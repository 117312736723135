@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: #1e93be;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: white;
  }
}

.text-ellipsis a {
  text-decoration: none !important;
  color: #1e93be;
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: black;
  }
}

.react-grid-item.react-grid-placeholder {
  background: #1e93be;
  opacity: 0.2;
  transition-duration: 100ms;
}

.highcharts-label.highcharts-data-label.highcharts-data-label-color-0 {
  display: none;
}

.react-grid-layout {
  min-width: 100vh;
  min-height: calc(100vh + 6000px);
}

.react-select__value-container {
  flex-wrap: nowrap !important;
}

.react-select__multi-value {
  min-width: 40% !important;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f4f4fa;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #1f3740;
  background-color: #1f3740;
}

.dx-tabpanel > .dx-tabpanel-tabs {
  box-shadow: none !important;

  & .dx-tabs {
    box-shadow: none !important;
    background-color: white !important;
  }

  & .dx-tab {
    padding-right: 0px !important;
    padding-left: 4px !important;
    box-shadow: none !important;
    background-color: white !important;
  }

  & .dx-tab.dx-tab-selected {
    border-bottom: 2px solid #1e93be !important;
  }
}

.dx-sortable {
  overflow: visible;
}

.dx-multiview-wrapper {
  border: none;
  display: none;
  box-shadow: none;
}
.dx-multiview-item.dx-item-selected {
  border: none;
  box-shadow: none;
  display: none;
}

#tabpanel-container .dx-tabs-wrapper {
  width: 100%;
}

#tabpanel-container .dx-tab {
  width: 150px;
}

.dx-button {
  background-color: white;
  box-shadow: none !important;
}

.dx-button-content i {
  color: #1e93be !important;
}

.fullscreen-enabled {
  overflow-y: scroll;
  overflow-x: hidden;
}
