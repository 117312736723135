/*----
GLOBAL STYLES
----*/

@import './generic/base.scss';
@import './generic/generic.scss';

/*----
TAILWIND
----*/

@tailwind base;
@tailwind components;
@tailwind utilities;

/*----
DEPENDENCIES
----*/

// react-grid-layout
@import url('../../../node_modules/react-grid-layout/css/styles.css');
@import url('../../../node_modules/react-resizable/css/styles.css');

// Toast
@import 'react-toastify/dist/ReactToastify.css';

// Skeleton loading
@import 'react-loading-skeleton/dist/skeleton.css';

// react-date-range (Datepicker)
@import 'react-date-range/dist/styles.css'; // main style file
@import 'react-date-range/dist/theme/default.css'; // theme css file

@import 'devextreme/dist/css/dx.light.css';
